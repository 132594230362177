@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #f3f3f3;
}

#root {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  font-family: "Mulish", sans-serif;
  background-color: #f3f3f3;
}

.faltuKaClass {
  margin-top: 69px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  font-family: 'Mulish', sans-serif;
  background-color: #f3f3f3;
}

.scrollable-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.scrollable-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

/* Hide scrollbar for WebKit and Blink-based browsers (Chrome, Safari, Opera) */
.scrollable-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

/* Hide scrollbar for Firefox */
.scrollable-container::-moz-scrollbar {
  width: 0;
  background-color: transparent;
}

.faltuKaClass {
  margin-top: 69px;
}

/* input field design */
.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-color: #000 !important;
}



.float-label-input {
  margin: 5px 0;
}

.float-label-input:focus-within label,
.float-label-input input:not(:placeholder-shown)+label {
  transform: translateY(-1.3rem) scale(0.80);
  background-color: white;
  color: #000000;
  font-weight: 600;
  letter-spacing: 1px;
}


#reload_href {
  /* padding: 10px 0; */
  background: black;
  color: white !important;
  padding: 5px;
  margin-top: 8px;
  font-size: 12px;
  transition: all 0.5s ease;
  user-select: none;
  box-shadow: 3px 3px 1px rgba(0, 0, 1, 0.644)
}


#reload_href:active {
  box-shadow: 0px 0px 1px rgba(0, 0, 1, 0.301);
  transition: all 0.5s ease;
}


#reload_href:hover {
  background: rgb(0, 0, 0);

  transition: all 0.2s ease;
}

#canv {
  margin-bottom: 8px
}