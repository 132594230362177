/* Secoundary Button */

.button-secoundary::before,
.button-secoundary::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.button-secoundary {
    border: 1px solid black;
    overflow: hidden;
    color: #fff;
    pointer-events: auto;
    cursor: pointer;
    margin: 0;
    font-family: inherit;
    position: relative;
    display: inline-block;
    font-size: 18px;
}

.button-secoundary span {
    display: block;
    position: relative;
}

.button-secoundary>span {
    overflow: hidden;
}

.button-secoundary>span>span {
    overflow: hidden;
    mix-blend-mode: difference;
}

.button-secoundary:hover>span>span {
    animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
}

.button-secoundary:hover {
    background: rgba(0, 0, 0, 0.1);
}

@keyframes MoveUpInitial {
    to {
        transform: translate3d(0, -105%, 0);
    }
}

@keyframes MoveUpEnd {
    from {
        transform: translate3d(0, 100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.button-secoundary::before {
    content: '';
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    transform-origin: 100% 50%;
}

.button-secoundary:hover::before {
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
}

/* Secoundary Button */


/* Sidebar */
@media (min-width:991px) {
    .css-1u2w381-MuiModal-root-MuiDrawer-root {
        display: none;
    }
}

.MuiPaper-root {
    width: 100%;
    background-color: #f3f3f3 !important;
}

/* Sidebar */